/* Built In Imports */
/* External Imports */
import { Box } from '@chakra-ui/react';
import { nanoid } from 'nanoid';

/* Internal Imports */
/* Components */
import config from '@config';
import RenderSections from '@sw/Common/RenderSections';
import WrapLayout from '@sw/components/Layout/WrapLayout';
import HeadComponent from '@sw/components/Utility/HeadComponent';

/* Services */
import { performRequest } from '@utils/microsites/DatoCMSHelperService';
import { getQuery } from '@utils/microsites/SWGqlQueries';

/* Styles */

/**
 *
 * @param {object} pageData
 * @param lang
 * @param region
 * @returns
 */

const SacredWalks = ({ pageData, region, lang }) => {
  return (
    <WrapLayout pageData={pageData}>
      <HeadComponent
        data={pageData?._seoMetaTags || pageData?.seoFields}
        availableLocals={pageData?.availableLocales}
      >
        {' '}
      </HeadComponent>
      <Box as="section" className="container-article" p="0" bgColor="#f8f8f8">
        {pageData?.body?.map(section => {
          return (
            <RenderSections
              pageData={pageData}
              section={section}
              region={region}
              lang={lang}
              key={nanoid(4)}
            />
          );
        })}
      </Box>
    </WrapLayout>
  );
};

export const getStaticProps = async () => {
  const response = await performRequest(getQuery('sacred-walks'));
  let pageData = response.allSacredWalksLandingPages[0] || [];
  pageData = JSON.stringify(pageData).replace(
    /https:\/\/www.datocms-assets.com\//g,
    `${config.staticPath}/d/`
  );
  pageData = JSON.parse(pageData);

  return {
    props: {
      pageData: pageData || '',
      menuType: 'sw',
    },
  };
};

export default SacredWalks;
